import React, { Fragment } from 'react'

import Layout from '../../components/layout'

import HalfImage from '../../components/halfImage/HalfImage'

import leftImage from './../../images/casestudies/car-part.png'

import { Link } from 'gatsby'

export default function casestudy3() {
  return (
    <Layout>
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="columns twelve">
              <h1 className="center-text">Project Title</h1>
              <p>Introduction to our story </p>
              <p>lorem ipsum dolor sit amet</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container"
        style={{ marginTop: '100px', marginBottom: '60px' }}
      >
        <div className="row">
          <div className="columns twelve">
            <h2>Prepared by Experts</h2>
          </div>
        </div>

        <div className="row">
          <div className="eight columns">
            <p>
              A description of this service and a list of what this service
              covers. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>

            <p>
              Ut enim ad minima veniam, quis nostrum exercitationem ullam
              corporis suscipit laboriosam, nisi ut aliquid ex ea commodi
              consequatur? Excepteur sint occaecat cupidatat non proident, sunt
              in culpa qui officia deserunt mollit anim id est laborum.
            </p>

            <p>
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
              officia deserunt mollit anim id est laborum.
            </p>
          </div>
          <div className="four columns">
            <ul>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt.
              </li>
              <li>
                Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                aut fugit, sed quia magni dolores eos qui ratione
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt.
              </li>
              <li>
                Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                aut fugit, sed quia
              </li>
            </ul>
          </div>
        </div>
      </div>
      <HalfImage
        image={leftImage}
        background
        imagePosition="right"
        text={
          <Fragment>
            <h2>Title for Image</h2>
            <p>
              Some copy about what we do and our services ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. Ut enim ad minim veniam, quis
              nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat. Duis aute irure dolor in reprehenderit in voluptate
              velit esse cillum dolore.
            </p>

            <Link to="/services">
              <button className="button-secondary">
                Full list of Services
              </button>
            </Link>
          </Fragment>
        }
      />

      <div className="full-primary-medium">
        <div className="container">
          <div className="row">
            <div className="columns twelve">
              <div className="testimonial-wrapper">
                <p className="testimonial">
                  “John Ashley was great to work with sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua.”
                </p>
                <p className="testimonialInfo">
                  Name, car type and service provided
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
